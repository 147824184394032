<template>
  <!-- :field-history="fieldHistory" -->
  <div>
    <b-alert :variant="'primary'" show class="cursor-pointer" >
      <div class="alert-body">
        <strong>
          <feather-icon icon="BookIcon" class="mr-25" />
          <b-badge variant="success">GREEN</b-badge> : Tamu udah masuk Area
        </strong>
      </div>
    </b-alert>
    <YoTable
      :key=tableKey
      ref-specify="FormApproval"
      :api-endpoint="apiEndpoint"
      title-tbl="this.$t('Dashboards') + ' Form Type 1 (T1)'"
      sub-title-tbl="this.$t('Land Vehicles')"
      :fields="fields"
      :new-data-template="newDataTemplate"
      :resolve-fetch-response="resolveFetchResponse"
      :resolve-fetch-url="resolveFetchUrl"
      :disable-edit="disableEdit"
      :multiple-header="multipleHeader"
      :force-disable-crud="true"
      enable-create-form="-"
      placeholder-search="-"
      />
  </div>
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/components/YoSimpleTable.vue'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    YoTable
  },
  data() {
    return {
      tableKey: 0,
      apiEndpoint: 'visitor_planning/history',
      titleTbl: 'DATA MASTER',
      disableEdit: true,
      multipleHeader: {
        startFrom: 1,
        content: [
          {
            value: 'Planning',
            span: '2',
            align: 'text-center'
          },
          {
            value: 'Tamu',
            span: '3',
            align: 'text-center'
          },
          {
            value: '',
            span: '1',
            align: 'text-center'
          },
          {
            value: 'Approved By',
            span: '2',
            align: 'text-center'
          }
        ],
        noEnd: true,
        endFrom: 7
      },
      fields: [
        {
          key: 'visitor_qrcode.visitor_public.visitor_cat.desc',
          label: 'Kunjungan Ke',
          sortable: true,
          placeholder: 'Sarana',
          input: { type: 'input-noarea' },
          searchModel: 'visitor_qrcode.visitor_public.visitor_cat.desc',
          search: {
            type: 'text'
          }
        },
        {
          key: 'ket',
          label: 'Ket. / Keperluan + Un Approval',
          sortable: true,
          // stickyColumn: true,
          placeholder: 'Nama Lengkap',
          input: { type: 'input-noarea' },
          searchModel: 'visitor_qrcode.visitor_public.full_name',
          search: {
            type: 'text'
          }
        },
        {
          key: 'visitor_qrcode.visitor_public.full_name',
          label: 'Full Name',
          sortable: true,
          placeholder: 'Nama Lengkap',
          input: { type: 'input-noarea' },
          searchModel: 'visitor_qrcode.visitor_public.full_name',
          search: {
            type: 'text'
          }
        },
        {
          key: 'visitor_qrcode.visitor_public.from_company',
          label: 'Asal Perusahaan / Instansi',
          sortable: true,
          placeholder: 'Asal Perusahaan / Instansi',
          input: { type: 'input-noarea' },
          searchModel: 'visitor_qrcode.visitor_public.from_company',
          search: {
            type: 'text'
          }
        },
        {
          key: 'visitor_qrcode.visitor_public.phone',
          label: 'Wa / Phone ( Clickable )',
          sortable: true,
          placeholder: 'Wa / Phone',
          input: { type: 'input-noarea' },
          searchModel: 'visitor_qrcode.visitor_public.phone',
          search: {
            type: 'text'
          }
        },
        {
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Created AT',
          searchModel: 'created_at',
          search: {
            type: 'text'
          },
          input: {
            type: 'date',
            format: 'dddd, D MMMM YYYY - HH:mm',
            disabled: true
          }
        },
        {
          key: 'approval_by_at',
          label: 'Approved AT',
          sortable: true,
          placeholder: 'Approved AT',
          searchModel: 'approval_by_at',
          search: {
            type: 'text'
          },
          input: {
            type: 'date',
            format: 'dddd, D MMMM YYYY - HH:mm',
            disabled: true
          }
        },
        {
          key: 'approval_by',
          label: 'Approved By',
          sortable: true,
          placeholder: 'Approved By',
          input: { type: 'input-noarea' },
          searchModel: 'approval_by',
          search: {
            type: 'text'
          }
        }
      ]
    }
  },
  setup() {
    const { watchNotification } = useAppConfig()
    return {
      watchNotification /* FirebaseFCM */
    }
  },
  watch: {
    watchNotification() {
      console.log('anIncomingNotification ::', this.watchNotification)
      try {
        let high_light_data = this.watchNotification.data[`screen_re_fetch`]
        let model = this.watchNotification.data[`model`]
        if (model === 'visitor_public') {
          let will_be_refresh = ['in-area-darat']
          let go_refresh_land = will_be_refresh.some((data) =>
            high_light_data.includes(data)
          )
          console.log('vgo_refresh_land:: land', go_refresh_land)
          if (go_refresh_land) {
            this.tableKey++ // Changes key → forces re-render
          }
        }
      } catch (error) {
        console.log('an error re fetch land', error)
      }
    }
  },
  computed: {
    newDataTemplate() {
      return {}
    }
  },
  methods: {
    moment,
    autoRefreshTest() {
      console.log('clicked')
      this.tableKey++ // Changes key → forces re-render
    },
    resolveFetchResponse(response) {
      return response.data.visitor_plannings
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    }
  }
}
</script>
